import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Asset } from "../models/asset";


export interface AssetsInterface {
  list: Asset[]
}

const initialState: AssetsInterface = {
  list: [],
};

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    updateAssets: (
      state,
      action: PayloadAction<AssetsInterface>
    ) => {
      state.list = action.payload.list;
    },
  },
});

export const { updateAssets } = assetsSlice.actions;

export default assetsSlice.reducer;
