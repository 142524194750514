import React from 'react'
import { Box, Button, Skeleton, useTheme } from '@mui/material'
import { useNavigate, useParams } from 'react-router'

import ThemeDialog from '../../../components/dialog-box/theme-dialog'
import { useDocumentTitle } from '../../../hooks/useDocumnetTitle'

const DroneBasicDetails = React.lazy(() => import('./drone-basic-details'))
const DroneLogs = React.lazy(() => import('./drone-logs'))


function DroneDetails() {


    const params = useParams()
    const { assetId } = params

    const assetID = atob(`${assetId}`)
    useDocumentTitle(`Asset (AWD-${assetID})`)

    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <>

            <ThemeDialog
                size='large'
                open={!!assetId}
                title={`Asset Details`}
                dialogBody={
                    <Box className={'flexBetweenTop wrap'}
                        sx={{
                            gap: 3,
                            pt: 3,
                            flex: 1,
                            '& .detail': {
                                fontSize: theme.typography.pxToRem(13),
                                columnGap: 1,
                                '&.secondary': {
                                    color: theme.palette.text.secondary,
                                },
                                '& span': {
                                    color: theme.palette.text.secondary,
                                },
                                '& svg': {
                                    height: theme.typography.pxToRem(13),
                                    width: theme.typography.pxToRem(13),
                                },
                                '&.sm': {
                                    fontSize: theme.typography.pxToRem(16),
                                    '& svg': {
                                        height: theme.typography.pxToRem(16),
                                        width: theme.typography.pxToRem(16),
                                    }
                                },

                            },
                            '& .MuiSkeleton-root': {
                                borderRadius: '28px',
                                transform: 'none'
                            }
                        }}>
                        <Box sx={{ flex: '1 1 450px' }}>
                            <React.Suspense fallback={<Skeleton height={200} width={"100%"} />}><DroneBasicDetails assetId={`${assetId}`} /></React.Suspense>
                        </Box>
                        <Box sx={{ flex: '2.5 1 600px', overflowX: 'auto' }}>
                            <React.Suspense fallback={<Skeleton height={200} width={"100%"} />}><DroneLogs assetId={`${assetId}`} /></React.Suspense>
                        </Box>
                    </Box>
                }
                dialogAction={
                    <Button onClick={() => navigate(-1)}>Close</Button>
                }
            />
        </>
    )
}

export default DroneDetails